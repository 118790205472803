import React from 'react';
import { Link } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar/BlogSidebar.js'
import VideoModal from '../ModalVideo/VideoModal'
import blogs from '../../api/blogs'
import axios from 'axios';
import { useState, useEffect } from 'react'
import moment from 'moment';
import { countTitle } from '../../utils/index.js';



const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const BlogList = (props) => {
    const [posts, setPosts] = useState([])
    // const [search, setSearch] = useState("")
    // const [searchPosts, setSearchPosts] = useState([]) 
    // const [isSearched, setIssearched] = useState(false)
    
    useEffect(() => {
        axios.get('https://www.api.dfit.fr/api/posts')
        .then(res => setPosts(res.data))
        .catch(err => console.log(err))
    }, [])

    // const SubmitHandler = (e) =>{
    //     e.preventDefault()
    //     const searchPosts= posts.filter(post => (post.title.includes(search) || post.content.includes(search)))
    //     setIssearched(true)
    //     setSearchPosts(searchPosts)
    // }

    return (
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">
            {/* <h1 style={{textAlign:"center"}}>Bientôt Disponible !</h1> */}
                <div className="row">
                    <div className={`col col-lg-8 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            {posts.map((post, bitem) => (
                                <div className={`post  ${post.blClass}`} key={bitem}>
                                    <div className="entry-media video-holder">
                                        <img src={post.image} alt="" />
                                        {/* <VideoModal /> */}
                                    </div>
                                    <div className="entry-meta">
                                        <ul>
                                            <li><i className="fi flaticon-user"></i> Par <Link onClick={ClickHandler} to={`/blog-single/${post.slug}`}>{post.author}</Link> </li>
                                            {/* <li><i className="fi flaticon-comment-white-oval-bubble"></i> {post.comment} Commentaires </li> */}
                                            <li><i className="fa fa-eye"></i> {post.vues} vues </li>
                                            <li><i className="fi flaticon-calendar"></i> publié le {moment(post.created_at).format('DD MMM YYYY')}</li>
                                        </ul>
                                    </div>
                                    <div className="entry-details">
                                        <h3><Link onClick={ClickHandler} to={`/blog-single/${post.slug}`}>{post.title}</Link></h3>
                                        <p>{countTitle(post.title)+"..."}</p>
                                        <Link onClick={ClickHandler} to={`/blog-single/${post.slug}`} className="read-more">Lire plus...</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {/* <BlogSidebar blLeft={props.blLeft} /> */}
                </div>
            </div>
        </section>

    )

}

export default BlogList;
