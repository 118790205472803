import React from 'react';
import { Link } from 'react-router-dom'
import blog3 from '../../images/blog-details/comments-author/wedding-user-8.png'
import { useParams } from 'react-router-dom'
import BlogSidebar from '../BlogSidebar/BlogSidebar.js'
import { dateParser } from '../../utils/index.js';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { useEffect, useState } from 'react';


const BlogSingle = (props) => {
    // const navigate = useNavigate()
    const { slug } = useParams()
    const [comments, setComments] = useState([]);
    const [post, setPost] = useState();
    const [name, setAuthor] = useState("");
    const [content, setText] = useState("");
    const [email, setMail] = useState("");


    const submitHandler = (e) => {
        e.preventDefault()
        const comt = { name, email, content, post_id: post.id }

        axios.post('https://www.api.dfit.fr/api/add-comment', comt)
            .then((res) => {
                // console.log(res.data)
                toast(res.data.message)
                getComments(post.id)
                    .then((res) => {
                        setComments(res)
                    })
                    .catch(err => toast.error(err.message));
                setAuthor("");
                setText("");
                setMail("");
            })
            .catch(err => {
                console(err.message)
                toast.error(err.message)
            })
    }

    useEffect(() => {
        if (slug) {
            getPost(slug)
                .then((result) => {
                    setPost(result)
                    getComments(result.id)
                        .then((res) => {
                            setComments(res)
                            // setLoading(false);
                            // ReactGA.event({
                            //     hitType: 'pageview',
                            //     page: window.location.pathname,
                            //     title: `BLOG : ${post.title}`
                            // });
                        })
                        .catch(err => toast.error(err.message));
                })
                .catch(err => toast.error(err.message));
        }

    }, [slug])

    async function getPost(slug) {
        const response = await axios.get("https://www.api.dfit.fr/api/posts/" + slug)
        return response.data
    }

    async function getComments(id) {
        const response = await axios.get("https://www.api.dfit.fr/api/comments/" + id)
        return response.data;
    }

    return ( 
         <section className="wpo-blog-single-section section-padding">
           {post && <div className="container">
                <div className="row">
                    <div className={`col col-lg-12 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={post.image} alt="" />
                                </div>
                                <div className="entry-meta">
                                    <ul>
                                        <li><i className="fi flaticon-user"> Par {post.author} </i></li>
                                        <li><i className="fi flaticon-comment-white-oval-bubble"></i> {comments.length} Commentaires</li>
                                        <li><i className="fa fa-eye"></i> {post.vues} vues </li>
                                        <li><i className="fi flaticon-calendar"></i> {moment(post.created_at).format('DD MMMM YYYY')} </li>
                                    </ul>
                                </div>
                                <h2>{post.title}</h2>
                                
                                <div dangerouslySetInnerHTML={{ __html: post.content}}></div>
                            </div>

                            <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title"> {comments.length} Commentaires</h3>
                                    <ol className="comments">
                                        {(comments.length !== 0 ) &&
                                            comments.map((comment ,i)=>(
                                                <li className={`post `} key={i} id="comment-1">
                                                    <div id="div-comment-1">
                                                        <div className="comment-theme">
                                                            <div className="comment-image"><img src={blog3} alt="" /></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4><span className="comments-date">{dateParser(comment.created_at)}</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>{comment.name}</p>
                                                                    <p>{comment.content}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ol>
                                </div>
                                <div className="comment-respond">
                                    {/* <h3 className="comment-reply-title">Poster un Commentaire</h3> */}
                                    <form onSubmit={submitHandler} id="commentform" className="comment-form">
                                        <div className="form-textarea">
                                            <textarea required value={content} onChange={(e)=>setText(e.target.value)} id="comment" placeholder="Ajouter un commentaire..."></textarea>
                                        </div>
                                        <div className="form-inputs">
                                            <input required value={name} onChange={(e)=>setAuthor(e.target.value)} placeholder="Nom" type="text" />
                                            <input required value={email} onChange={(e)=>setMail(e.target.value)} placeholder="Email" type="email" />
                                        </div>
                                        <div className="form-submit">
                                            <input id="submit" value="Poster" type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <BlogSidebar blLeft={props.blLeft} /> */}
                </div>
            </div>}
        </section>
    )

}

export default BlogSingle;
