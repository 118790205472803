import React from 'react';
import { Link } from "react-router-dom";


const Offres = () => {
    return (
        <section className='wpo-blog-pg-section section-padding'>
        <style>
                {`
                .card-container {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    margin: 20px;
                }

                .card {
                    padding: 10px;
                    text-align: center;
                    width: 400px;
                    margin-top: 5px;
                }

                .card img {
                    max-width: 100%;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .card h2 {
                    font-size: 1.5em;
                    margin: 10px 0;
                }

                .card a {
                    text-decoration: none;
                    color: #333;
                }

                .card p {
                    font-size: 1em;
                    color: #000;
                }
                `}
            </style>
            <div className='container'>
                <div className='row'>
                    <div className="card-container row">
                        <h1 style={{fontWeight:"bold", textAlign:"center"}}>Promo</h1>
                        <div className="card">
                            <img src="/product/visuels/reduction.jpg" alt="Image 1" /><br/>
                            {/* <h2 style={{fontWeight:"bold"}}>30 Days Weight Loss Challenge</h2> */}
                            {/* <p>Ceci est un texte descriptif pour la première carte.</p> */}
                        </div>
                    </div><br/>

                    <div className="card-container row">
                        <h1 style={{fontWeight:"bold", textAlign:"center"}}> Nos Challenges</h1>
                        <div className="card">
                            <img src="/product/visuels/challenge-1.jpg" alt="Image 1" /><br/>
                            {/* <h2 style={{fontWeight:"bold"}}>30 Days Weight Loss Challenge</h2> */}
                            {/* <p>Ceci est un texte descriptif pour la première carte.</p> */}
                        </div>
                        <div className="card">
                            <img src="/product/visuels/New-year.jpg" alt="Image 2" /><br/>
                            {/* <h2 style={{fontWeight:"bold"}}>Bonamoussadi</h2> */}
                            {/* <p>Ceci est un texte descriptif pour la deuxième carte.</p> */}
                        </div>
                    </div><br/>

                    <div className="card-container row">
                        <h1 style={{fontWeight:"bold", textAlign:"center"}}> Nos Différentes Offres</h1>
                        <div className="card">
                            <img src="/product/visuels/FORMATION-CUISINE.jpg" alt="Image 1" /><br/>
                            {/* <h2 style={{fontWeight:"bold"}}>30 Days Weight Loss Challenge</h2> */}
                            {/* <p>Ceci est un texte descriptif pour la première carte.</p> */}
                        </div>
                        <div className="card">
                            <img src="/product/visuels/Sauces.png" alt="Image 2" /><br/>
                            {/* <h2 style={{fontWeight:"bold"}}>Bonamoussadi</h2> */}
                            {/* <p>Ceci est un texte descriptif pour la deuxième carte.</p> */}
                        </div>
                        <div className="card">
                            <img src="/product/visuels/smoothies.jpg" alt="Image 2" /><br/>
                            {/* <h2 style={{fontWeight:"bold"}}>Bonamoussadi</h2> */}
                            {/* <p>Ceci est un texte descriptif pour la deuxième carte.</p> */}
                        </div>
                    </div><br/>
                </div>
            </div>
        </section>
    );
}

export default Offres;